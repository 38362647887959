<template>
  <div>
    <base-info-card
      :title="title"
      :subtitle="subtitle"
      space="4"
      color="primary"
    />

    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Merci pour votre demande.
        </v-card-title>
        <v-card-text>La demande a bien été envoyée à ImpôtAD. Vous recevrez une réponse dans les plus brefs délais.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      id="contactForm"
    >
      <v-text-field
        name="name"
        v-model="contactForm.name"
        :rules="nameRules"
        label="Nom"
        required
      ></v-text-field>

      <v-text-field
        name="email"
        v-model="contactForm.email"
        :rules="emailRules"
        label="Adresse courriel"
        required
      ></v-text-field>

      <v-text-field
        name="subject"
        v-model="contactForm.subject"
        :rules="subjectRules"
        label="Sujet"
        required
      ></v-text-field>

      <v-textarea
        name="message"
        label="Description de votre besoin ou votre question"
        v-model="contactForm.message"
        :rules="messageRules"
        auto-grow
        value=""
      ></v-textarea>
    </v-form>

    <base-btn
      :disabled="!valid"
      :color="!theme.isDark ? 'accent' : 'white'"
      @click="sendEmail"
      outlined
      target="#"
    >
      Envoyer le message
    </base-btn>
  </div>
</template>

<script>
import emailjs, { init } from 'emailjs-com';
init("user_IISIKm7CdoGGtHG0giVty");

  export default {
    name: 'BaseContactForm',

    // Injected from the Vuetify Themeable mixin
    inject: ['theme'],

    props: {
      subtitle: String,
      title: {
        type: String,
        default: 'Demande d\'information',
      },
    },
    data: () => {
      return {
        defaultContactForm: {
          name: '',
          email: '',
          subject: '',
          message: '',
        },
        contactForm: {
          name: '',
          email: '',
          subject: '',
          message: '',
        },
        valid: true,
        nameRules: [
          v => !!v || 'Vous devez spécifier votre nom.',
        ],
        subjectRules: [
          v => !!v || 'Vous devez spécifier un sujet.',
        ],
        messageRules: [
          v => !!v || 'Vous devez écrire un message.',
        ],
        emailRules: [
          v => !!v || 'Vous devez inscrire une adresse courriel.',
          v => /.+@.+\..+/.test(v) || 'Le format de l\'adresse courriel est invalide.',
        ],
        dialog: false,
      }
    },
    methods: {
      async sendEmail() {
        if (!this.$refs.form.validate()) {
          return;
        } else {
          await emailjs.sendForm('service_bxpezyq', 'template_z6mt2ue', '#contactForm')

          this.contactForm = this.defaultContactForm;
          this.$refs.form.resetValidation();
          this.dialog = true;
        }
      },
    }
  }
</script>

<style scoped>
.v-card__text {
  letter-spacing: 0.12rem;
}
</style>
