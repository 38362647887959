import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import BlockContent from 'sanity-blocks-vue-component'

Vue.config.productionTip = false

Vue.component('block-content', BlockContent);

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')
