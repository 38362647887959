import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#2E6ADD',
        secondary: '#eaeaea',
        accent: '#2E6ADD',
      },
      dark: {},
    },
  },
})