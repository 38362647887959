<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-info-card
        :title="title"
        color="primary"
      >
        <slot />
      </base-info-card>

      <div v-if="data">
        <template v-for="({ icon, text, title: t }, i) in business">
          <base-avatar-card
            :key="i"
            :icon="icon"
            :outlined="false"
            :title="!dense ? t : undefined"
            color="transparent"
            horizontal
            space="0"
          >
            <!-- Do not use v-html for user -->
            <!-- provided values -->
            <div v-html="text" />
          </base-avatar-card>
  
          <v-divider
            v-if="i + 1 !== business.length"
            :key="`divider-${i}`"
            class="my-2"
          />
        </template>
      </div>
    </div>
  </v-theme-provider>
</template>

<script>

import sanity from "@/client";

const query = `*[_type == "homepage"]{
  _id,
  name,
  phone_number,
  email
}[0]`;

  export default {
    name: 'BaseBusinessContact',

    props: {
      dark: Boolean,
      dense: Boolean,
      title: String,
    },

    created() {
      this.fetchData();
    },

    computed: {
      business() {
        return [
          this.nameSection,
          this.phoneSection,
          this.emailSection
        ]
      },
      nameSection() {
       return {
          icon: 'mdi-account',
          title: 'Personne-ressource',
          text: this.data !== null ? this.data.name : ""
        };
      },
      phoneSection() {
       return {
        icon: 'mdi-cellphone',
          title: 'Téléphone',
          text:  this.data !== null ? this.data.phone_number : ""
        };
      },
      emailSection() {
       return {
          icon: 'mdi-email',
          title: 'Courriel',
          text:  this.data !== null ? this.data.email : ""
        };
      },
    },
    data: () => ({
      data: null,
    }),
    methods: {
      fetchData() {
        sanity.fetch(query).then(
          (data) => {
            this.data = data;
          },
          (error) => {
            console.log(error);
          }
        );
      },
    }
  }
</script>
